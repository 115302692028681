import React, { useContext, useEffect, useState } from "react";
import style from "./UpdatePlan.module.css";
import {
  ButtonWithoutIcon,
  HeaderNavigation,
  validateModuleAccess,
  ViewMessage,
  IconWrapper,
  // @ts-ignore
} from "@viuti/recursos";
import { IPlanStore, usePlanStore } from "@Stores/usePlanStore";
import useAvailablePlans from "@Hooks/useAvailablePlans";
import { useNavigate, useParams } from "react-router-dom";
import { getPlanFeatures } from "./functions/getPlanFeatures";
import SimpleSubscriptionCard from "./components/SimpleSubscriptionCard/SimpleSubscriptionCard";
import { HiChevronRight } from "react-icons/hi2";
import SimpleFeaturesCard from "./components/SimpleFeaturesCard/SimpleFeaturesCard";
import { changeModalUpdatePlanPay } from "@Hooks/changeModalUpdatePlanPay/changeModalUpdatePlanPay";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";
import { ROUTES } from "@Models/routes";
import { IBillingStore, useBillingStore } from "@Stores/useBillingStore";
import { getBillingData_adapter } from "@Adapters/getBillingData.adapter";
import { ModalPlanEdit } from "@Components/ui/molecules/BillingCard/ModalPlanEdit/ModalPlanEdit";
import { MODIFY_BILLING_INFO_UUID } from "@Models/const/securityAccess";

const UpdatePlan = () => {
  const navigate = useNavigate();
  useAvailablePlans();
  const { availablePlans, availablePlansFetchResponse } =
    usePlanStore() as IPlanStore;
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;
  const {
    billingInformation,
    setBillingInformation,
    billingInformationFetchResponse,
    setBillingInformationFetchResponse,
  } = useBillingStore() as IBillingStore;

  const { planId, subscriptionId } = useParams();
  const [isBillingInfoModalVisible, setIsBillingInfoModalVisible] =
    useState(false);

  const currentPlan = availablePlans?.find(
    (plan) => plan.isCurrentPlan.currentPlanSub
  );
  const currentSubscription = currentPlan?.subscriptions?.find(
    (subscription) =>
      subscription.id === currentPlan?.isCurrentPlan.periodSuscriptionId
  );

  const desiredPlan = availablePlans?.find(
    (plan) => plan.id === Number(planId)
  );

  const desiredSubscription = desiredPlan?.subscriptions?.find(
    (subscription) => subscription.id === Number(subscriptionId)
  );

  const [features, setFeatures] = useState({ before: [], after: [] });
  useEffect(() => {
    const result = getPlanFeatures(
      availablePlans,
      currentPlan?.id,
      desiredPlan?.id
    );
    setFeatures(result);
  }, [availablePlans, currentPlan, desiredPlan]);

  useEffect(() => {
    if (
      availablePlansFetchResponse.status === "success" &&
      !desiredSubscription
    ) {
      navigate(`${ROUTES.AVAILABLE_PLANS_ROUTE}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePlansFetchResponse, desiredPlan]);

  useEffect(() => {
    (async () => {
      if (billingInformationFetchResponse.status !== "success") {
        setBillingInformationFetchResponse({
          status: "loading",
          message: "",
        });
        const response = await getBillingData_adapter({
          setBillingInformation,
        });
        setBillingInformationFetchResponse({
          status: response.isSuccess ? "success" : "error",
          message: response.message,
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.view__container}>
      <HeaderNavigation
        title={"Actualizar suscripción"}
        previousAction={() => window.history.back()}
        previousActionMovement={"back"}
        buttonProps={{
          textBttn: "",
          handleClick: () => {},
          isDisabled: true,
          isPrimary: true,
          isHidden: true,
        }}
      />
      <div className={style.content}>
        <ViewMessage
          title={"¡Importante!"}
          message={`Los beneficios de tu empresa están a punto de cambiar. Hoy mismo podrás verlos y explorar las funciones que harán crecer tu negocio.`}
        />

        <div className={style.header_information}>
          <h2 className={style.advisory__title}>Confirmar suscripción</h2>
          <p className={style.advisory__description}>
            Con esto autorizas a Prikly a cobrarte automáticamente cada mes
            hasta que finalice tu periodo de suscripción. Puedes consultar los
            términos y condiciones haciendo{" "}
            <a
              href="https://www.zymple.io/terminos-y-condiciones"
              target="_blank"
            >
              click aquí
            </a>
            .
          </p>
          <span className={style.advisory__buttons}>
            <ButtonWithoutIcon
              textBttn={"Actualizar mi suscripción"}
              handleClick={() => {
                if (!billingInformation.id)
                  return (
                    validateModuleAccess(MODIFY_BILLING_INFO_UUID) &&
                    setIsBillingInfoModalVisible(true)
                  );
                changeModalUpdatePlanPay(
                  modalContext,
                  desiredPlan,
                  desiredSubscription,
                  "compra"
                  // desiredSubscription.isAnually ? "compra" : "suscripcion"
                );
              }}
              isPrimary
              width="fit-content"
            />
            <ButtonWithoutIcon
              textBttn={"Volver"}
              handleClick={() => window.history.back()}
              isPrimary={false}
              width="fit-content"
            />
          </span>
        </div>
        <hr className={style.divisor} />
        <div className={style.header_information}>
          <h1 className={style.header_information__title}>
            ¿Cómo afectará a mi empresa este cambio?
          </h1>
          <span className={style.header_information__description}>
            <p className={style.header_information__message}>
              {currentPlan?.id === desiredPlan?.id
                ? "Las características de tu cuenta permanecerán igual ya que estás cambiando entre períodos del mismo plan. Aplicaremos el tiempo pago que queda en tu cuenta a tu nueva suscripción. Tu nueva fecha de facturación será el día en que se acabe ese crédito."
                : "Al cambiar de suscripción hoy verás estos cambios en tu cuenta. Ten en consideración que cuando confirmes el cambio no tendrás acceso a las funciones que tu nuevo plan no permita. Aplicaremos el tiempo pago que queda en tu cuenta a tu nuevo plan. Tu nueva fecha de facturación será el día en que se acabe ese crédito."}
            </p>
          </span>
        </div>
        <section className={style.card_comparission__container}>
          {currentPlan && (
            <>
              <SimpleSubscriptionCard
                isCurrent={false}
                plan={currentPlan}
                subscription={currentSubscription}
              />
              <span className={style.card_comparission__icon}>
                <IconWrapper icon={HiChevronRight} size={48} color="#9E9E9E" />
              </span>
            </>
          )}
          <SimpleSubscriptionCard
            isCurrent={false}
            plan={desiredPlan}
            subscription={desiredSubscription}
          />
        </section>
        {currentPlan?.id !== desiredPlan?.id && (
          <section className={style.card_comparission__container}>
            {currentPlan && (
              <>
                <SimpleFeaturesCard
                  isBefore={true}
                  features={features.before}
                  color={currentPlan?.hexColors.primary_50}
                />
                <span className={style.card_comparission__icon}>
                  <IconWrapper
                    icon={HiChevronRight}
                    size={48}
                    color="#9E9E9E"
                  />
                </span>
              </>
            )}
            <SimpleFeaturesCard
              isBefore={false}
              features={features.after}
              color={desiredPlan?.hexColors.primary_50}
            />
          </section>
        )}
      </div>
      {isBillingInfoModalVisible && (
        <ModalPlanEdit
          setModalIsVisible={setIsBillingInfoModalVisible}
          initialValues={billingInformation}
          successCallback={() => {
            setIsBillingInfoModalVisible(false);
            changeModalUpdatePlanPay(
              modalContext,
              desiredPlan,
              desiredSubscription,
              "compra"
              // desiredSubscription.isAnually ? "compra" : "suscripcion"
            );
          }}
        />
      )}
    </div>
  );
};

export default UpdatePlan;
