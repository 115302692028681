import { ICurrentPlan } from "@Models/interfaces/plan";
import { getCurrentPlan_service } from "@Services/account/getCurrentPlan.service";
import { updateCurrentPlanStore } from "@Stores/usePlanStore";
import { formatDate } from "@Utilities/FormatsHandler";

export const getCurrentPlan_adapter = async (): Promise<IResponse> => {
  const response = await getCurrentPlan_service();

  if (!response.isSuccess) {
    return response;
  }

  const data = response.data;

  const isPaymentNeeded = data.paymentRequired;
  let planBillStatusMessage: string;
  if (isPaymentNeeded) {
    planBillStatusMessage = `Realiza el próximo pago de S/ ${
      data.priceWithTax
    } antes del ${formatDate(data.dateEnd)} para continuar usando este plan.`;
  } else {
    planBillStatusMessage = `Tu suscripción estará activa hasta el ${formatDate(
      data.dateEnd
    )}.`;
  }

  const currentPlanResponse: ICurrentPlan = {
    id: data.planSelectedId,
    category: data.planCategory,
    name: data.planName,
    users: {
      quantity: 1,
      unlimited: false,
    },
    features: data.features.map((feature) => ({
      name: feature.name,
      isBoosted: feature.isBoosted,
    })),
    isFreeTrial: data.freeTrail,
    subscription: {
      id: data.periodSuscriptionId,
      months: data.periodSuscriptionMonthPeriod,
      name: `cada ${data.periodSuscriptionMonthPeriod} ${
        data.periodSuscriptionMonthPeriod === 1 ? "mes" : "meses"
      }`,
      price: {
        withIGV: data.priceWithTax,
        withoutIGV: data.priceWithoutTax,
      },
      dateEnd: formatDate(data.dateEnd),
      dateIssue: formatDate(data.dateIssue),
      remainingDays: data.remainingDays,
    },
    isPaymentOnValidation: data.paymentValidationWindow,
    userMessage: data.userMessage,
    planStatus: {
      isPaymentNeeded,
      billStatusMessage: planBillStatusMessage,
      typeId: data.typeSuscription || null,
      typeName: data.typeSuscriptionDescription
        ? data.typeSuscriptionDescription.charAt(0).toUpperCase() +
          data.typeSuscriptionDescription.slice(1).toLowerCase()
        : null,
    },
  };

  updateCurrentPlanStore(currentPlanResponse);

  return response;
};
