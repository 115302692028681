import React from "react";
import style from "./PaymentMessage.module.css";
import { formatDate, formatToSoles } from "@Utilities/FormatsHandler";
import { IPlanStore, usePlanStore } from "@Stores/usePlanStore";

interface IPaymentMessage {
  isTrialSubscription: boolean;
}

const PaymentMessage: React.FC<IPaymentMessage> = ({ isTrialSubscription }) => {
  const { currentPlan } = usePlanStore() as IPlanStore;

  return (
    <>
      {isTrialSubscription ? (
        <TrialSubscriptionMessage
          daysRemaining={currentPlan.subscription.remainingDays}
          nextBillAmount={currentPlan.subscription.price.withoutIGV}
        />
      ) : (
        <SubscriptionMessage
          billAmount={currentPlan.subscription.price.withoutIGV}
          dueDate={currentPlan.subscription.dateEnd}
          paymenMethodName="Visa"
          paymentMethodDigits="1974"
          isPaymentOnValidation={currentPlan.isPaymentOnValidation}
          isPaymentNeeded={currentPlan.planStatus.isPaymentNeeded}
          billStatusMessage={currentPlan.planStatus.billStatusMessage}
        />
      )}
    </>
  );
};

interface ISubscriptionMessage {
  billAmount: number;
  dueDate: string;
  paymenMethodName: string;
  paymentMethodDigits: string;
  isPaymentOnValidation: boolean;
  isPaymentNeeded: boolean;
  billStatusMessage: string;
}

const SubscriptionMessage: React.FC<ISubscriptionMessage> = ({
  billAmount,
  dueDate,
  paymenMethodName,
  paymentMethodDigits,
  isPaymentOnValidation,
  isPaymentNeeded,
  billStatusMessage,
}) => {
  return (
    <span className={style.payment}>
      {isPaymentOnValidation ? (
        <p className={style.remain_payment__message}>
          Estamos validando tu pago. Mientras tanto, disfruta de los beneficios
          de tu plan.
        </p>
      ) : (
        <p className={style.remain_payment__message}>{billStatusMessage}</p>
      )}
      {/* <p className={style.details_payment__message}>
				{paymenMethodName} terminada en **** **** **** {paymentMethodDigits}
			</p> */}
    </span>
  );
};

interface TrialSubscriptionMessage {
  daysRemaining: number;
  nextBillAmount: number;
}

const TrialSubscriptionMessage: React.FC<TrialSubscriptionMessage> = ({
  daysRemaining,
  nextBillAmount,
}) => {
  return (
    <span className={style.payment}>
      <p className={style.remain_payment__message}>
        Actualmente estás en un plan <b>gratuito</b>.
        {/* Tu periodo de prueba finalizará en <b>{daysRemaining} días</b>. */}
        {/* Luego se
        cobrará <b>{formatToSoles(nextBillAmount)}</b> para seguir usando la
        plataforma */}
      </p>
      <p className={style.details_payment__message}>
        {/* Si decides seguir utilizando Prikly, por favor ajusta tus métodos de
        pago. */}
        Para disfrutar de más funciones y beneficios, puedes optar por un plan
        de pago.
      </p>
    </span>
  );
};

export default PaymentMessage;
