import React, { useContext, useEffect, useState } from "react";
import style from "./SubscriptionPricesModal.module.css";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";
import { ISubscription } from "@Models/interfaces/plan";
import { formatToSoles } from "@Utilities/FormatsHandler";
// @ts-ignore
import { CircularRadioInput, IconWrapper } from "@viuti/recursos";
import { SubscriptionPlanCardProps } from "@Components/ui/molecules/SubscriptionPlanCard/SubscriptionPlanCard";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@Models/routes";
import { IPlanStore, usePlanStore } from "@Stores/usePlanStore";
import { HiFire } from "react-icons/hi2";
import { changeModalUpdatePlanPay } from "@Hooks/changeModalUpdatePlanPay/changeModalUpdatePlanPay";

interface ISubscriptionModal {
  plan: SubscriptionPlanCardProps;
}

const SubscriptionPricesModal: React.FC<ISubscriptionModal> = ({ plan }) => {
  const navigate = useNavigate();
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;
  const { currentPlan } = usePlanStore() as IPlanStore;
  const { subscriptions } = plan;

  const [selectedSubscription, setSelectedSubscription] =
    useState<ISubscription>(null);

  const handleChange = (subscription) => {
    if (currentPlan?.subscription.id === subscription.id) return;
    setSelectedSubscription(subscription);
  };

  const handleModalClose = () => {
    setSelectedSubscription(null);
    modalContext?.actionButtonClose();
    modalContext.handleButtonTwoChange({
      ...modalContext.buttonTwo,
      isDisabled: true,
    });
  };

  const handleSubmit = () => {
    const linkUrl = ROUTES.UPDATE_PLAN_ROUTE.replace(
      ":planId",
      String(plan.id)
    ).replace(":subscriptionId", String(selectedSubscription.id));
    navigate(linkUrl);
    setSelectedSubscription(null);
    handleModalClose();
  };

  const handlePayCurrentPlan = () => {
    console.log("currentPlan", currentPlan);

    if (currentPlan && currentPlan.subscription) {
      changeModalUpdatePlanPay(
        modalContext,
        {
          id: currentPlan.id,
          category: currentPlan.category,
          name: currentPlan.name,
          users: {
            quantity: currentPlan.users.quantity,
            unlimited: currentPlan.users.unlimited,
          },
          price: currentPlan.subscription.price.withIGV,
          features: currentPlan.features,
          isCurrentPlan: {
            currentPlanSub: true,
            periodSuscriptionId: currentPlan.subscription.id,
          },
          subscriptions: null,
          hexColors: {
            primary_50: null,
            primary_70: null,
            primary_90: null,
          },
        },
        {
          id: currentPlan.subscription.id,
          mpPreaprovalPlanId: null,
          name: currentPlan.subscription.name,
          months: currentPlan.subscription.months,
          prices: {
            withIGV: currentPlan.subscription.price.withIGV,
            withoutIGV: currentPlan.subscription.price.withoutIGV,
          },
          equalsMonthly: null,
          saves: null,
          isAnually: null,
        },
        "compra"
      );
    }
  };

  useEffect(() => {
    if (selectedSubscription) {
      modalContext.handleButtonTwoChange({
        ...modalContext.buttonTwo,
        isDisabled: false,
        actionButtonTwo: handleSubmit,
      });
    } else {
      modalContext.handleButtonTwoChange({
        ...modalContext.buttonTwo,
        isDisabled: true,
      });
    }
    modalContext.handleActionButtonCloseChange(() => {
      handleModalClose();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubscription]);

  return (
    <div className={style.container}>
      <p className={style.advice}>
        Escoge el periodo de suscripción que mas se ajuste a tus preferencias.
        Al finalizar el periodo tu plan se renovará de forma automática. Cambia
        o cancela tu plan cuando lo necesites.
      </p>
      <div className={style.prices__container}>
        {subscriptions
          .map((subscription) => {
            const isCurrentSubscription =
              currentPlan?.subscription.id === subscription.id;
            return (
              <button
                className={`${style.card} ${
                  selectedSubscription?.id === subscription.id
                    ? style.selected
                    : ""
                } ${isCurrentSubscription ? style.current_subscription : ""}`}
                key={subscription.id}
                onClick={() => handleChange(subscription)}
              >
                <div className={style.subscription__container}>
                  <span className={style.subscription_prices}>
                    <span className={style.amount}>
                      <b>{formatToSoles(subscription.prices.withoutIGV)}</b> +
                      IGV <b>cada {subscription.name}</b>
                    </span>
                    {subscription.months === 12 && (
                      <p className={style.equaly_price}>
                        Equivalente a{" "}
                        <b>
                          {formatToSoles(subscription.equalsMonthly)} al mes
                        </b>
                      </p>
                    )}
                  </span>
                  {subscription.saves && subscription.months > 1 ? (
                    <span
                      className={style.save_chip}
                      style={{
                        background:
                          subscription.months < 12
                            ? plan.hexColors.primary_90
                            : "linear-gradient(135deg, rgb(124 58 237) 0%, rgb(78 70 229) 100%)",
                        color: subscription.months < 12 ? "#000000" : "#ffffff",
                      }}
                    >
                      {subscription.months === 12 && (
                        <IconWrapper icon={HiFire} size={14} color="#ffffff" />
                      )}
                      Ahorra {formatToSoles(subscription.saves)}
                    </span>
                  ) : null}
                </div>
                {!isCurrentSubscription ? (
                  <CircularRadioInput
                    id={subscription.id}
                    checked={selectedSubscription?.id === subscription.id}
                    onClick={null}
                  />
                ) : currentPlan?.planStatus?.isPaymentNeeded ? (
                  <p>Suscripción actual</p>
                ) : (
                  <button
                    className={style.renew_button}
                    onClick={() => handlePayCurrentPlan()}
                  >
                    Renovar ahora
                  </button>
                )}
              </button>
            );
          })
          .reverse()}
      </div>
    </div>
  );
};

export default SubscriptionPricesModal;
