import React, { useEffect, useContext } from "react";
import style from "./ManageSubscription.module.css";
import {
  ButtonWithoutIcon,
  HeaderNavigation,
  ViewMessage,
  validateModuleAccess,
  // @ts-ignore
} from "@viuti/recursos";
import BillingCard from "@Components/ui/molecules/BillingCard/BillingCard";
import { getCurrentPlan_adapter } from "@Adapters/suscriptions/getCurrentPlan.adapter";
import { getBillingData_adapter } from "@Adapters/getBillingData.adapter";
import { IBillingStore, useBillingStore } from "@Stores/useBillingStore";
import { IPlanStore, usePlanStore } from "@Stores/usePlanStore";
import CurrentPlan from "@Components/ui/molecules/CurrentPlan/CurrentPlan";
import ModalWrapper from "@Components/wrapper/ModalWrapper/ModalWrapper";
import useCancellationProcess from "./hook/useCancellationProcess";
import CurrentSubscriptionBanner from "./components/CurrentSubscriptionBanner/CurrentSubscriptionBanner";
import { UPDATE_SUBSCRIPTION_UUID } from "@Models/const/securityAccess";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";
import { changeModalUpdatePlanPay } from "@Hooks/changeModalUpdatePlanPay/changeModalUpdatePlanPay";

interface IManageSubscriptionProps {}
const ManageSubscription: React.FC<IManageSubscriptionProps> = () => {
  const {
    setBillingInformation,
    billingInformationFetchResponse,
    setBillingInformationFetchResponse,
  } = useBillingStore() as IBillingStore;

  const { setCurrentPlanFetchResponse, currentPlan, currentPlanFetchResponse } =
    usePlanStore() as IPlanStore;

  const cancelationModalProps = useCancellationProcess();
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;

  useEffect(() => {
    (async () => {
      if (currentPlanFetchResponse.status !== "success") {
        setCurrentPlanFetchResponse({
          status: "loading",
          message: "",
        });
        const response = await getCurrentPlan_adapter();
        setCurrentPlanFetchResponse({
          status: response.isSuccess ? "success" : "error",
          message: response.message,
        });
      }

      if (billingInformationFetchResponse.status !== "success") {
        setBillingInformationFetchResponse({
          status: "loading",
          message: "",
        });
        const response = await getBillingData_adapter({
          setBillingInformation,
        });
        setBillingInformationFetchResponse({
          status: response.isSuccess ? "success" : "error",
          message: response.message,
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePayCurrentPlan = () => {
    if (currentPlan && currentPlan.subscription) {
      changeModalUpdatePlanPay(
        modalContext,
        {
          id: currentPlan.id,
          category: currentPlan.category,
          name: currentPlan.name,
          users: {
            quantity: currentPlan.users.quantity,
            unlimited: currentPlan.users.unlimited,
          },
          price: currentPlan.subscription.price.withIGV,
          features: currentPlan.features,
          isCurrentPlan: {
            currentPlanSub: true,
            periodSuscriptionId: currentPlan.subscription.id,
          },
          subscriptions: null,
          hexColors: {
            primary_50: null,
            primary_70: null,
            primary_90: null,
          },
        },
        {
          id: currentPlan.subscription.id,
          mpPreaprovalPlanId: null,
          name: currentPlan.subscription.name,
          months: currentPlan.subscription.months,
          prices: {
            withIGV: currentPlan.subscription.price.withIGV,
            withoutIGV: currentPlan.subscription.price.withoutIGV,
          },
          equalsMonthly: null,
          saves: null,
          isAnually: null,
        },
        "compra"
      );
    }
  };

  return (
    <div className={style.view__container}>
      <HeaderNavigation
        title={"Administrar suscripción"}
        previousAction={() => window.history.back()}
        previousActionMovement={"back"}
        buttonProps={{
          textBttn: "",
          handleClick: () => {},
          isDisabled: true,
          isPrimary: true,
          isHidden: true,
        }}
      />
      <div className={`${style.row} ${style.plan_row}`}>
        <BillingCard
          hasError={billingInformationFetchResponse.status === "error"}
          isLoading={billingInformationFetchResponse.status === "loading"}
        />
        <CurrentPlan />
      </div>
      {/* <ViewMessage
				title={'Configuración de medio de pagos'}
				message={`Ajusta tu medio de pago de preferencia para realizar el pago de tu 
          suscripción. Este también se utilizará para otras compras que hagas 
          con tu cuenta de Prikly.`}
			/> */}
      <CurrentSubscriptionBanner />
      {currentPlan?.planStatus?.isPaymentNeeded && (
        <ButtonWithoutIcon
          isPrimary={true}
          textBttn={"Renovar mi plan actual"}
          width={"fit-content"}
          handleClick={handlePayCurrentPlan}
        />
      )}
      <ViewMessage
        title={"Cancelación de suscripción"}
        message={`Tu acceso a las funciones premium continuará hasta el final del 
          período de facturación actual. Luego de confirmar podrás recuperar la 
          información de tu empresa pero esta ya no formará parte de Prikly.`}
      />
      <ButtonWithoutIcon
        isPrimary={false}
        textBttn={"Cancelar mi suscripción"}
        width={"fit-content"}
        handleClick={() =>
          validateModuleAccess(UPDATE_SUBSCRIPTION_UUID) &&
          cancelationModalProps.visibleProps.changeVisibilities(true)
        }
      />
      <ModalWrapper
        {...cancelationModalProps.currentCancelationProps}
        isVisible={cancelationModalProps.visibleProps.isVisible}
        onCloseModal={cancelationModalProps.cancelOperation}
      />
    </div>
  );
};

export default ManageSubscription;
