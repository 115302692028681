import React from "react";
import style from "./PaymentResponse.module.css";
import {
  ButtonWithoutIcon,
  LoadingSpinner,
  IconWrapper,
  // @ts-ignore
} from "@viuti/recursos";
import { HiCheckBadge, HiExclamationCircle } from "react-icons/hi2";

const PaymentResponse = ({ paymentResponse, handleTryAgain, handleClose }) => (
  <div className={style.payment__container}>
    {paymentResponse.loading ? (
      <div className={style.payment_loading__container}>
        <LoadingSpinner size={48} />
        <p className={style.payment_loading__message}>
          Validando pago, por favor espere...
        </p>
      </div>
    ) : (
      <>
        <div className={style.payment__icon__container}>
          <span className={style.payment__icon}>
            {paymentResponse.error ? (
              <IconWrapper
                icon={HiExclamationCircle}
                size={64}
                color="#d82121"
              />
            ) : (
              <IconWrapper icon={HiCheckBadge} size={64} color="#00de66" />
            )}
          </span>
        </div>
        <h1 className={style.payment__title}>
          {paymentResponse.error
            ? "¡Hubo un error al procesar el pago!"
            : "¡Pago realizado con éxito!"}
        </h1>
        <p className={style.payment__message}>{paymentResponse?.message}</p>
        <ButtonWithoutIcon
          isPrimary
          textBttn={paymentResponse.error ? "Reintentar" : "Entendido"}
          handleClick={paymentResponse.error ? handleTryAgain : handleClose}
          width="100%"
        />
      </>
    )}
  </div>
);

export default PaymentResponse;
