import React, { useEffect, useState } from "react";
import {
  ModalAcceptCancel,
  SelectInput,
  TextInput,
  DualBars,
  NoInfoToShow,
  IconWrapper,
  // @ts-ignore
} from "@viuti/recursos";
import style from "./ModalPlanEdit.module.css";
import { types } from "./mock_data/mock_data";
import ValidationInput from "./components/validationInput/ValidationInput";
import { useDepartaments } from "@Hooks/useDepartaments";
import { useProvinces } from "@Hooks/useProvinces";
import { useDistricts } from "@Hooks/useDistricts";
import { useSearchReniec } from "./hooks/useSearchReniec";
import {
  handleBlurDNINumber,
  handleBlurRUCNumber,
  handleBlurName,
  handleBlurReasonSocial,
  handleBlurEmail,
  handleBlurAddress,
  handleBlurDistrict,
  handleBlurDepartment,
  handleBlurProvince,
} from "./functions/handleBlur";
import {
  handleChangeType,
  handleChangeDNINumber,
  handleChangeRUCNumber,
  handleChangeName,
  handleChangeReasonSocial,
  handleChangeEmail,
  handleChangeDepartment,
  handleChangeProvince,
  handleChangeDistrict,
  handleChangeAddress,
} from "./functions/handleChange";
import {
  Error,
  Form,
  ModalPlanEditProps,
} from "./interfaces/IModalPlanEdit.interface";
import useFormReset from "./hooks/useFormReset";
import { useErrorReset } from "./hooks/useErrorReset";
import { useButtonDisabled } from "./hooks/useButtonDisabled";
import { handleSubmit } from "./functions/handleSubmit";
import { PERSONA_NATURAL_ID } from "@Models/consts";
import { HiMapPin } from "react-icons/hi2";
import { IToasterStore, useToasterStore } from "@Stores/useToasterStore";
import { IBillingStore, useBillingStore } from "@Stores/useBillingStore";

export const ModalPlanEdit: React.FC<ModalPlanEditProps> = ({
  setModalIsVisible,
  initialValues,
  successCallback = () => {
    setModalIsVisible(false);
  },
}) => {
  const { setBillingInformation } = useBillingStore() as IBillingStore;
  const { setToaster } = useToasterStore() as IToasterStore;
  const defaultForm = {
    billingId: null,
    typeId: null,
    identityNumber: "",
    identityName: "",
    reasonSocial: "",
    email: "",
    departmentId: null,
    provinceId: null,
    districtId: null,
    address: "",
  };
  const [initialFormValues, setInitialFormValues] = useState<Form>(defaultForm);
  const [formValues, setFormValues] = useState<Form>(initialFormValues);
  const [error, setError] = useState<Error>(defaultForm);
  const [criticalError, setCriticalError] = useState<string>("");
  const [loadingLocation, setLoadingLocation] = useState<string>("");
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const { status } = useSearchReniec({
    form: formValues,
    setForm: setFormValues,
  });
  const { departaments } = useDepartaments(
    setCriticalError,
    setLoadingLocation
  );
  const { provinces, setProvinces } = useProvinces(
    formValues.departmentId,
    setCriticalError,
    setLoadingLocation
  );
  const { districts, setDistricts } = useDistricts(
    formValues.provinceId,
    setCriticalError,
    setLoadingLocation
  );
  useFormReset({ form: formValues, setForm: setFormValues, setError });
  useErrorReset({ form: formValues, setError });
  useButtonDisabled({ form: formValues, setButtonDisabled });

  useEffect(() => {
    const initialState = {
      billingId: initialValues?.id,
      typeId: initialValues?.entity?.id,
      identityNumber: initialValues?.identity?.number,
      identityName: initialValues?.identity?.name,
      reasonSocial: "",
      email: initialValues?.email,
      departmentId: initialValues?.department?.id,
      provinceId: initialValues?.province?.id,
      districtId: initialValues?.district?.id,
      address: initialValues?.address,
    };
    setInitialFormValues(initialState);
    setFormValues(initialState);
  }, [initialValues]);

  if (criticalError) {
    return (
      <ModalAcceptCancel
        title={"Facturación de Prikly"}
        showButtons={{
          showButtonOne: false,
          showButtonTwo: true,
          showButtonClose: true,
        }}
        buttonTwo={{
          textButtonTwo: "Guardar",
          actionButtonTwo: () => {},
          isDisabled: true,
          isLoading: false,
        }}
        actionButtonClose={() => {
          setModalIsVisible(false);
        }}
      >
        <section className={style.modal}>
          <NoInfoToShow
            title={"!Ups! Hubo un error"}
            message={criticalError}
            error={true}
          >
            <IconWrapper icon={HiMapPin} size={40} color="#b91616" />
          </NoInfoToShow>
        </section>
      </ModalAcceptCancel>
    );
  }

  return (
    <ModalAcceptCancel
      title={"Facturación de Prikly"}
      showButtons={{
        showButtonOne: false,
        showButtonTwo: true,
        showButtonClose: true,
      }}
      buttonTwo={{
        textButtonTwo: "Guardar",
        actionButtonTwo: async () =>
          await handleSubmit(
            formValues,
            {
              setButtonLoading,
              setToaster,
              setBillingInformation,
            },
            successCallback
          ),
        isDisabled: buttonDisabled || buttonLoading || criticalError,
        isLoading: buttonLoading,
      }}
      actionButtonClose={() => {
        setModalIsVisible(false);
      }}
    >
      <section className={style.modal}>
        <article className={style.row}>
          <SelectInput
            label="Tipo de persona"
            placeholder="Seleccionar tipo de persona"
            value={formValues.typeId}
            items={types}
            handleChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleChangeType(e, setFormValues)
            }
            name="typeId"
            required
          />
          {formValues.typeId === PERSONA_NATURAL_ID ? (
            <TextInput
              label="Número de DNI"
              type="text"
              name="identityNumber"
              placeholder="Ingresar número de DNI"
              width="100%"
              flex={1}
              value={formValues.identityNumber}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeDNINumber(e, setFormValues)
              }
              handleBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleBlurDNINumber(e, setError)
              }
              touched={error.identityNumber}
              error={error.identityNumber}
              required
            />
          ) : (
            <ValidationInput
              label="Número de RUC"
              name="identityNumber"
              value={formValues.identityNumber}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeRUCNumber(e, setFormValues)
              }
              placeholder="Ingresar número de RUC"
              maxLength={11}
              width="100%"
              flex={1}
              handleBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleBlurRUCNumber(e, setError)
              }
              status={{
                error: !!error.identityNumber,
                validated: false,
                loading: status.loading,
                message: status.message || error.identityNumber,
              }}
              disabled={status.loading}
            />
          )}
        </article>
        <article className={style.row}>
          <TextInput
            label="Correo electrónico"
            type="email"
            name="email"
            placeholder="Ingresar correo electrónico"
            width="100%"
            flex={1}
            value={formValues.email}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeEmail(e, setFormValues)
            }
            handleBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleBlurEmail(e, setError)
            }
            disabled={status.loading}
            touched={error.email}
            error={error.email}
            required
          />
          {formValues.typeId === PERSONA_NATURAL_ID ? (
            <TextInput
              label="Nombre y apellido"
              type="text"
              name="identityName"
              placeholder="Ingresar nombre"
              width="100%"
              flex={1}
              value={formValues.identityName}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeName(e, setFormValues)
              }
              handleBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleBlurName(e, setError)
              }
              touched={error.identityName}
              error={error.identityName}
              required
            />
          ) : (
            <TextInput
              label="Razón social"
              type="text"
              name="identityName"
              placeholder="Ingresar razón social"
              width="100%"
              flex={1}
              value={formValues.identityName}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeReasonSocial(e, setFormValues)
              }
              handleBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleBlurReasonSocial(e, setError)
              }
              disabled={status.loading}
              touched={error.identityName}
              error={error.identityName}
              required
            />
          )}
        </article>

        {loadingLocation ? (
          <h2 className={style.loadingOverlay}>
            {/* <img src={LoadingPulseIcon} alt="Cargando" /> */}
            {loadingLocation}
            <DualBars />
          </h2>
        ) : (
          <article className={style.row}>
            <SelectInput
              label={"Departamento"}
              value={formValues.departmentId}
              handleChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleChangeDepartment(e, setFormValues, setProvinces)
              }
              handleBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleBlurDepartment(e, setError)
              }
              keyboardType={"text"}
              name={"departmentId"}
              flex={1}
              items={departaments}
              touched={error.departmentId}
              error={error.departmentId}
              required
            />
            <SelectInput
              label={"Provincia"}
              value={formValues.provinceId}
              handleChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleChangeProvince(e, setFormValues, setDistricts)
              }
              handleBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleBlurProvince(e, setError)
              }
              keyboardType={"text"}
              name={"province"}
              flex={1}
              items={provinces}
              disabled={!formValues.departmentId}
              touched={error.provinceId}
              error={error.provinceId}
              required
            />
            <SelectInput
              label={"Distrito"}
              value={formValues.districtId}
              handleChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleChangeDistrict(e, setFormValues)
              }
              handleBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleBlurDistrict(e, setError)
              }
              keyboardType={"text"}
              name={"district"}
              flex={1}
              items={districts}
              disabled={!formValues.provinceId}
              touched={error.districtId}
              error={error.districtId}
              required
            />
          </article>
        )}
        <article className={style.row}>
          <TextInput
            label="Dirección"
            type="text"
            name="address"
            placeholder="Ingresar dirección"
            value={formValues.address}
            width="100%"
            flex={1}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeAddress(e, setFormValues)
            }
            handleBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleBlurAddress(e, setError)
            }
            disabled={status.loading}
            touched={error.address}
            error={error.address}
            required
          />

          <TextInput
            label="Comprobante a enviar"
            type="text"
            name=""
            placeholder=""
            width="100%"
            flex={1}
            value={
              formValues.typeId === PERSONA_NATURAL_ID
                ? "Boleta de venta"
                : "Factura electrónica"
            }
            handleChange={() => {}}
            handleBlur={() => {}}
            disabled={true}
          />
        </article>
      </section>
    </ModalAcceptCancel>
  );
};
