import { HiSparkles } from "react-icons/hi2";
import styles from "./styles/DiscountOffer.module.css";
// @ts-ignore
import { TextInput, IconWrapper } from "@viuti/recursos";
import { Fragment, useState } from "react";

const DiscountOffer = ({
  showSecurityStep = false,
  changeStepStatus = (b: boolean) => {},
}) => {
  const [cancelationText, setCancelationText] = useState<string>("");
  const [isTextInputDirty, setIsTextInputDirty] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTextInputDirty(true);
    setCancelationText(e.target.value);

    if (e.target.value === "Cancelar suscripción") {
      changeStepStatus(true);
    } else changeStepStatus(false);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const handleCopy = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const errorMessage =
    cancelationText !== "Cancelar suscripción"
      ? "Por favor escribe 'Cancelar suscripción'"
      : "";

  return (
    <section className={styles.bodyModal__container}>
      <p>
        Antes de que canceles tu suscripción, nos gustaría ofrecerte un
        descuento especial.
      </p>
      <section className={styles.bodyModal__warning}>
        <IconWrapper icon={HiSparkles} size={22} color="#F4C300" />
        <article className={styles.bodyModal__warning__message}>
          <strong>Apreciamos tu confianza</strong>
          <p>
            Por eso te ofrecemos un <strong>20%</strong> de descuento en los
            próximos <strong>3 meses</strong> si decides mantener tu
            suscripción.
          </p>
        </article>
      </section>
      {showSecurityStep && (
        <Fragment>
          <p>
            Para confirmar tu intención de eliminar tu cuenta, por favor escribe
            “Cancelar suscripción” en el siguiente cuadro:
          </p>
          <TextInput
            type="text"
            name="cancelationText"
            placeholder="Escribe aquí"
            width="100%"
            flex={1}
            value={cancelationText}
            handleChange={handleChange}
            touched={isTextInputDirty}
            error={errorMessage}
            required
            inputProps={{
              onPaste: handlePaste,
              onCopy: handleCopy,
            }}
          />
        </Fragment>
      )}
    </section>
  );
};

export default DiscountOffer;
