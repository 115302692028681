// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CircularProgress.module.css */
.Ux16YPxwf_a2AYdy1qXN {
  position: relative;
  height: auto;
  aspect-ratio: 1;
}

.nEZqcAi5AVjbg7OiFMcx {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.GmCJ98Ul5OmYcYtoKxxG {
  fill: none;
  stroke-width: 4;
  stroke-linecap: round;
  transition: stroke-dashoffset 500ms ease-in-out, stroke 500ms ease-in-out;
}

.GmCJ98Ul5OmYcYtoKxxG:nth-child(1) {
  stroke: #e0e0e0;
}

.GmCJ98Ul5OmYcYtoKxxG:nth-child(2) {
  stroke-dashoffset: 0;
}

.rvG1r3fKWWy14inq8WSI {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: calc(100% - 25px - 25px);
}

.NiYlWFDGTr8r2v8vnR7Z {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  gap: 8px;
}

.TcsYOMR8eFJf2La6XtYc {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.en4vMCVvkJuRVMkN2Nu2 {
  font-size: 1rem;
  color: #989898;
}

.KHRzQmtknV4B4VFlqfNO {
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/CircularProgress/CircularProgress.module.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;EACE,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,eAAe;EACf,qBAAqB;EACrB,yEAAyE;AAC3E;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["/* CircularProgress.module.css */\n.container {\n  position: relative;\n  height: auto;\n  aspect-ratio: 1;\n}\n\n.progressRing {\n  width: 100%;\n  height: 100%;\n  transform: rotate(-90deg);\n}\n\n.progressRingCircle {\n  fill: none;\n  stroke-width: 4;\n  stroke-linecap: round;\n  transition: stroke-dashoffset 500ms ease-in-out, stroke 500ms ease-in-out;\n}\n\n.progressRingCircle:nth-child(1) {\n  stroke: #e0e0e0;\n}\n\n.progressRingCircle:nth-child(2) {\n  stroke-dashoffset: 0;\n}\n\n.content {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  text-align: center;\n  width: calc(100% - 25px - 25px);\n}\n\n.empty_container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  font-size: 0.875rem;\n  gap: 8px;\n}\n\n.fraction {\n  font-size: 2rem;\n  font-weight: 600;\n  margin-bottom: 5px;\n}\n\n.separator {\n  font-size: 1rem;\n  color: #989898;\n}\n\n.label {\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Ux16YPxwf_a2AYdy1qXN`,
	"progressRing": `nEZqcAi5AVjbg7OiFMcx`,
	"progressRingCircle": `GmCJ98Ul5OmYcYtoKxxG`,
	"content": `rvG1r3fKWWy14inq8WSI`,
	"empty_container": `NiYlWFDGTr8r2v8vnR7Z`,
	"fraction": `TcsYOMR8eFJf2La6XtYc`,
	"separator": `en4vMCVvkJuRVMkN2Nu2`,
	"label": `KHRzQmtknV4B4VFlqfNO`
};
export default ___CSS_LOADER_EXPORT___;
