import React from "react";
import style from "./SubscriptionPlanCard.module.css";
import { HiMiniUser } from "react-icons/hi2";
import { ISubscriptionPlan } from "@Models/interfaces/plan";
import { formatToSoles } from "@Utilities/FormatsHandler";
import FeatureItem from "@Components/ui/atoms/FeatureItem/FeatureItem";
// @ts-ignore
import { DualBars, IconWrapper } from "@viuti/recursos";

interface Footer {
  type: "button" | "text" | "none";
  content?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export interface SubscriptionPlanCardProps extends ISubscriptionPlan {
  footer?: Footer;
  isLoading?: boolean;
  isCurrentPlan: any;
}

const SubscriptionPlanCard: React.FC<SubscriptionPlanCardProps> = ({
  category,
  name,
  users,
  price,
  features,
  footer,
  isCurrentPlan,
  hexColors,
  isLoading = false,
}) => {
  return (
    <article className={style.card}>
      {!isLoading && (
        <div className={style.currentPlanTagContainer}>
          {isCurrentPlan?.currentPlanSub && (
            <div
              className={style.currentPlanTag}
              style={{ backgroundColor: hexColors.primary_50 }}
            >
              Plan actual
            </div>
          )}
        </div>
      )}
      <div className={style.container}>
        {isLoading ? (
          <DualBars />
        ) : (
          <>
            <div className={style.content}>
              <div className={style.details}>
                <p className={style.planType}>{category}</p>
                <p
                  className={style.planName}
                  style={{ color: hexColors.primary_50 }}
                >
                  {name}
                </p>
                <span className={style.planUsersContainer}>
                  <span className={style.planUserIconContainer}>
                    <IconWrapper
                      icon={HiMiniUser}
                      size={15}
                      className={style.planUserIcon}
                      style={{ color: hexColors.primary_50 }}
                    />
                  </span>
                  <p className={style.planUsersMessage}>
                    {users.unlimited
                      ? "Usuarios ilimitados"
                      : `Hasta ${users.quantity} usuarios`}
                  </p>
                </span>
                {price && (
                  <p className={style.planPrice}>
                    <b>{formatToSoles(price)}</b> + IGV
                  </p>
                )}
              </div>
              <hr className={style.divisor} />
              <div className={style.featuresContainer}>
                {features.map((feature) => (
                  <FeatureItem
                    key={feature.name}
                    {...feature}
                    backgroundColor={hexColors.primary_50}
                  />
                ))}
              </div>
            </div>
            {price && (
              <div className={style.cardFooter}>
                {footer.type === "button" && (
                  <button
                    style={{ backgroundColor: hexColors.primary_50 }}
                    onClick={footer.onClick}
                    className={style.footerButton}
                    disabled={footer.disabled}
                  >
                    {footer.content}
                  </button>
                )}
                {footer.type === "text" && <p>{footer.content}</p>}
              </div>
            )}
          </>
        )}
      </div>
    </article>
  );
};

export default SubscriptionPlanCard;
